// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/countries.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Country, GetCountriesRequest, GetCountriesResponse, GetCountryRequest } from "./countries_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.CountryService
 */
export const CountryService = {
  typeName: "avn.connect.v1.CountryService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.CountryService.GetCountry
     */
    getCountry: {
      name: "GetCountry",
      I: GetCountryRequest,
      O: Country,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CountryService.GetCountries
     */
    getCountries: {
      name: "GetCountries",
      I: GetCountriesRequest,
      O: GetCountriesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

