// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/tags.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from enum avn.connect.v1.TagFilterCondition
 */
export enum TagFilterCondition {
  /**
   * @generated from enum value: TAG_FILTER_CONDITION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_ANY_OF = 1;
   */
  HAS_ANY_OF = 1,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_ALL_OF = 2;
   */
  HAS_ALL_OF = 2,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_NONE_OF = 3;
   */
  HAS_NONE_OF = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TagFilterCondition)
proto3.util.setEnumType(TagFilterCondition, "avn.connect.v1.TagFilterCondition", [
  { no: 0, name: "TAG_FILTER_CONDITION_UNSPECIFIED" },
  { no: 1, name: "TAG_FILTER_CONDITION_HAS_ANY_OF" },
  { no: 2, name: "TAG_FILTER_CONDITION_HAS_ALL_OF" },
  { no: 3, name: "TAG_FILTER_CONDITION_HAS_NONE_OF" },
]);

/**
 * @generated from enum avn.connect.v1.TagGroupId
 */
export enum TagGroupId {
  /**
   * @generated from enum value: TAG_GROUP_ID_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: TAG_GROUP_ID_SUBJECT = 1;
   */
  SUBJECT = 1,

  /**
   * @generated from enum value: TAG_GROUP_ID_AGE_RANGE = 2;
   */
  AGE_RANGE = 2,

  /**
   * @generated from enum value: TAG_GROUP_ID_PLATFORM = 10;
   */
  PLATFORM = 10,

  /**
   * @generated from enum value: TAG_GROUP_ID_MEDIA = 28;
   */
  MEDIA = 28,
}
// Retrieve enum metadata with: proto3.getEnumType(TagGroupId)
proto3.util.setEnumType(TagGroupId, "avn.connect.v1.TagGroupId", [
  { no: 0, name: "TAG_GROUP_ID_UNKNOWN" },
  { no: 1, name: "TAG_GROUP_ID_SUBJECT" },
  { no: 2, name: "TAG_GROUP_ID_AGE_RANGE" },
  { no: 10, name: "TAG_GROUP_ID_PLATFORM" },
  { no: 28, name: "TAG_GROUP_ID_MEDIA" },
]);

/**
 * @generated from enum avn.connect.v1.TagId
 */
export enum TagId {
  /**
   * @generated from enum value: TAG_ID_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Information
   *
   * @generated from enum value: TAG_ID_EDUVERSE_AI = 2745;
   */
  EDUVERSE_AI = 2745,

  /**
   * @generated from enum value: TAG_ID_AI_GENERATED = 2747;
   */
  AI_GENERATED = 2747,

  /**
   * @generated from enum value: TAG_ID_SKYBOX_AI = 2746;
   */
  SKYBOX_AI = 2746,

  /**
   * @generated from enum value: TAG_ID_CLASSS_CANVAS = 2778;
   */
  CLASSS_CANVAS = 2778,

  /**
   * @generated from enum value: TAG_ID_NOT_BROWSABLE = 2427;
   */
  NOT_BROWSABLE = 2427,

  /**
   * @generated from enum value: TAG_ID_NAVIGABLE = 2683;
   */
  NAVIGABLE = 2683,

  /**
   * Media
   *
   * @generated from enum value: TAG_ID_FLAT = 2715;
   */
  FLAT = 2715,

  /**
   * @generated from enum value: TAG_ID_EQUIRECTANGULAR_360 = 2363;
   */
  EQUIRECTANGULAR_360 = 2363,

  /**
   * @generated from enum value: TAG_ID_EQUIRECTANGULAR_180 = 2550;
   */
  EQUIRECTANGULAR_180 = 2550,

  /**
   * @generated from enum value: TAG_ID_CUBEMAP = 2373;
   */
  CUBEMAP = 2373,

  /**
   * @generated from enum value: TAG_ID_STEREO_TB_LR = 2362;
   */
  STEREO_TB_LR = 2362,

  /**
   * @generated from enum value: TAG_ID_STEREO_SBS = 2364;
   */
  STEREO_SBS = 2364,

  /**
   * @generated from enum value: TAG_ID_AR = 2367;
   */
  AR = 2367,

  /**
   * @generated from enum value: TAG_ID_VR = 2366;
   */
  VR = 2366,

  /**
   * @generated from enum value: TAG_ID_SCENE_GUIDE = 2656;
   */
  SCENE_GUIDE = 2656,

  /**
   * @generated from enum value: TAG_ID_LESSON_PLAN = 2372;
   */
  LESSON_PLAN = 2372,

  /**
   * @generated from enum value: TAG_ID_WORKSHEET = 2371;
   */
  WORKSHEET = 2371,

  /**
   * @generated from enum value: TAG_ID_ANIMATED = 2613;
   */
  ANIMATED = 2613,

  /**
   * @generated from enum value: TAG_ID_INTERACTIVE = 2697;
   */
  INTERACTIVE = 2697,

  /**
   * @generated from enum value: TAG_ID_SCENE = 2375;
   */
  SCENE = 2375,

  /**
   * @generated from enum value: TAG_ID_AVATAR = 2666;
   */
  AVATAR = 2666,

  /**
   * @generated from enum value: TAG_ID_MODEL = 2374;
   */
  MODEL = 2374,

  /**
   * @generated from enum value: TAG_ID_AUDIO = 2655;
   */
  AUDIO = 2655,

  /**
   * @generated from enum value: TAG_ID_IMAGE = 2376;
   */
  IMAGE = 2376,

  /**
   * @generated from enum value: TAG_ID_VIDEO = 2377;
   */
  VIDEO = 2377,

  /**
   * @generated from enum value: TAG_ID_TOPIC = 2741;
   */
  TOPIC = 2741,

  /**
   * @generated from enum value: TAG_ID_THREE_DOF = 2776;
   */
  THREE_DOF = 2776,

  /**
   * @generated from enum value: TAG_ID_SIX_DOF = 2777;
   */
  SIX_DOF = 2777,

  /**
   * Age Ranges
   *
   * @generated from enum value: TAG_ID_AGE_RANGE_UNDER_5 = 17;
   */
  AGE_RANGE_UNDER_5 = 17,

  /**
   * @generated from enum value: TAG_ID_AGE_RANGE_5_TO_7 = 18;
   */
  AGE_RANGE_5_TO_7 = 18,

  /**
   * @generated from enum value: TAG_ID_AGE_RANGE_7_TO_11 = 19;
   */
  AGE_RANGE_7_TO_11 = 19,

  /**
   * @generated from enum value: TAG_ID_AGE_RANGE_11_TO_14 = 20;
   */
  AGE_RANGE_11_TO_14 = 20,

  /**
   * @generated from enum value: TAG_ID_AGE_RANGE_14_TO_16 = 21;
   */
  AGE_RANGE_14_TO_16 = 21,

  /**
   * @generated from enum value: TAG_ID_AGE_RANGE_OVER_16 = 22;
   */
  AGE_RANGE_OVER_16 = 22,
}
// Retrieve enum metadata with: proto3.getEnumType(TagId)
proto3.util.setEnumType(TagId, "avn.connect.v1.TagId", [
  { no: 0, name: "TAG_ID_UNKNOWN" },
  { no: 2745, name: "TAG_ID_EDUVERSE_AI" },
  { no: 2747, name: "TAG_ID_AI_GENERATED" },
  { no: 2746, name: "TAG_ID_SKYBOX_AI" },
  { no: 2778, name: "TAG_ID_CLASSS_CANVAS" },
  { no: 2427, name: "TAG_ID_NOT_BROWSABLE" },
  { no: 2683, name: "TAG_ID_NAVIGABLE" },
  { no: 2715, name: "TAG_ID_FLAT" },
  { no: 2363, name: "TAG_ID_EQUIRECTANGULAR_360" },
  { no: 2550, name: "TAG_ID_EQUIRECTANGULAR_180" },
  { no: 2373, name: "TAG_ID_CUBEMAP" },
  { no: 2362, name: "TAG_ID_STEREO_TB_LR" },
  { no: 2364, name: "TAG_ID_STEREO_SBS" },
  { no: 2367, name: "TAG_ID_AR" },
  { no: 2366, name: "TAG_ID_VR" },
  { no: 2656, name: "TAG_ID_SCENE_GUIDE" },
  { no: 2372, name: "TAG_ID_LESSON_PLAN" },
  { no: 2371, name: "TAG_ID_WORKSHEET" },
  { no: 2613, name: "TAG_ID_ANIMATED" },
  { no: 2697, name: "TAG_ID_INTERACTIVE" },
  { no: 2375, name: "TAG_ID_SCENE" },
  { no: 2666, name: "TAG_ID_AVATAR" },
  { no: 2374, name: "TAG_ID_MODEL" },
  { no: 2655, name: "TAG_ID_AUDIO" },
  { no: 2376, name: "TAG_ID_IMAGE" },
  { no: 2377, name: "TAG_ID_VIDEO" },
  { no: 2741, name: "TAG_ID_TOPIC" },
  { no: 2776, name: "TAG_ID_THREE_DOF" },
  { no: 2777, name: "TAG_ID_SIX_DOF" },
  { no: 17, name: "TAG_ID_AGE_RANGE_UNDER_5" },
  { no: 18, name: "TAG_ID_AGE_RANGE_5_TO_7" },
  { no: 19, name: "TAG_ID_AGE_RANGE_7_TO_11" },
  { no: 20, name: "TAG_ID_AGE_RANGE_11_TO_14" },
  { no: 21, name: "TAG_ID_AGE_RANGE_14_TO_16" },
  { no: 22, name: "TAG_ID_AGE_RANGE_OVER_16" },
]);

/**
 * @generated from message avn.connect.v1.Tag
 */
export class Tag extends Message<Tag> {
  /**
   * @generated from field: int32 tag_id = 1;
   */
  tagId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: int32 tag_group_id = 3;
   */
  tagGroupId = 0;

  /**
   * @generated from field: int32 sort_order = 4;
   */
  sortOrder = 0;

  /**
   * @generated from field: string hex_color = 5;
   */
  hexColor = "";

  constructor(data?: PartialMessage<Tag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Tag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "hex_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tag {
    return new Tag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJsonString(jsonString, options);
  }

  static equals(a: Tag | PlainMessage<Tag> | undefined, b: Tag | PlainMessage<Tag> | undefined): boolean {
    return proto3.util.equals(Tag, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TagGroup
 */
export class TagGroup extends Message<TagGroup> {
  /**
   * @generated from field: int32 tag_group_id = 1;
   */
  tagGroupId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: string language_id = 3;
   */
  languageId = "";

  /**
   * @generated from field: repeated avn.connect.v1.Tag tags = 4;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<TagGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TagGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TagGroup {
    return new TagGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TagGroup {
    return new TagGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TagGroup {
    return new TagGroup().fromJsonString(jsonString, options);
  }

  static equals(a: TagGroup | PlainMessage<TagGroup> | undefined, b: TagGroup | PlainMessage<TagGroup> | undefined): boolean {
    return proto3.util.equals(TagGroup, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagsRequest
 */
export class GetTagsRequest extends Message<GetTagsRequest> {
  /**
   * @generated from field: repeated int32 tag_ids = 1;
   */
  tagIds: number[] = [];

  /**
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined, b: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined): boolean {
    return proto3.util.equals(GetTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagsResponse
 */
export class GetTagsResponse extends Message<GetTagsResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.Tag tags = 1;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<GetTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined, b: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined): boolean {
    return proto3.util.equals(GetTagsResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagGroupRequest
 */
export class GetTagGroupRequest extends Message<GetTagGroupRequest> {
  /**
   * @generated from field: int32 tag_group_id = 1;
   */
  tagGroupId = 0;

  /**
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetTagGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagGroupRequest | PlainMessage<GetTagGroupRequest> | undefined, b: GetTagGroupRequest | PlainMessage<GetTagGroupRequest> | undefined): boolean {
    return proto3.util.equals(GetTagGroupRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagGroupResponse
 */
export class GetTagGroupResponse extends Message<GetTagGroupResponse> {
  /**
   * @generated from field: avn.connect.v1.TagGroup tag_group = 1;
   */
  tagGroup?: TagGroup;

  constructor(data?: PartialMessage<GetTagGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group", kind: "message", T: TagGroup },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagGroupResponse | PlainMessage<GetTagGroupResponse> | undefined, b: GetTagGroupResponse | PlainMessage<GetTagGroupResponse> | undefined): boolean {
    return proto3.util.equals(GetTagGroupResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddTagsRequest
 */
export class AddTagsRequest extends Message<AddTagsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated int32 tags = 3;
   */
  tags: number[] = [];

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: optional string legacy_id = 10;
   */
  legacyId?: string;

  constructor(data?: PartialMessage<AddTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined, b: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined): boolean {
    return proto3.util.equals(AddTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RemoveTagsRequest
 */
export class RemoveTagsRequest extends Message<RemoveTagsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated int32 tags = 3;
   */
  tags: number[] = [];

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: optional string legacy_id = 10;
   */
  legacyId?: string;

  constructor(data?: PartialMessage<RemoveTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RemoveTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined, b: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined): boolean {
    return proto3.util.equals(RemoveTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TagFilter
 */
export class TagFilter extends Message<TagFilter> {
  /**
   * @generated from field: avn.connect.v1.TagFilterCondition condition = 1;
   */
  condition = TagFilterCondition.UNSPECIFIED;

  /**
   * @generated from field: repeated int32 tags = 2;
   */
  tags: number[] = [];

  constructor(data?: PartialMessage<TagFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TagFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "condition", kind: "enum", T: proto3.getEnumType(TagFilterCondition) },
    { no: 2, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TagFilter {
    return new TagFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TagFilter {
    return new TagFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TagFilter {
    return new TagFilter().fromJsonString(jsonString, options);
  }

  static equals(a: TagFilter | PlainMessage<TagFilter> | undefined, b: TagFilter | PlainMessage<TagFilter> | undefined): boolean {
    return proto3.util.equals(TagFilter, a, b);
  }
}

