// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/geo.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GeoSuggestRequest, GeoSuggestResponse, GetGeoPlaceRequest, GetGeoPlaceResponse } from "./geo_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.GeoService
 */
export const GeoService = {
  typeName: "avn.connect.v1.GeoService",
  methods: {
    /**
     * Find a place using a given id
     *
     * @generated from rpc avn.connect.v1.GeoService.GetGeoPlace
     */
    getGeoPlace: {
      name: "GetGeoPlace",
      I: GetGeoPlaceRequest,
      O: GetGeoPlaceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Lightweight searching suitable for things like autocomplete
     *
     * @generated from rpc avn.connect.v1.GeoService.GeoSuggest
     */
    geoSuggest: {
      name: "GeoSuggest",
      I: GeoSuggestRequest,
      O: GeoSuggestResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

