// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/entities.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";
import { Authorization } from "./authorization_pb.js";
import { TranscodeImageSpec } from "./media_pb.js";
import { TagFilter } from "./tags_pb.js";

/**
 * @generated from enum avn.connect.v1.SearchFlags
 */
export enum SearchFlags {
  /**
   * @generated from enum value: SEARCH_FLAGS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SEARCH_FLAGS_EXACT_MATCH = 1;
   */
  EXACT_MATCH = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SearchFlags)
proto3.util.setEnumType(SearchFlags, "avn.connect.v1.SearchFlags", [
  { no: 0, name: "SEARCH_FLAGS_UNSPECIFIED" },
  { no: 1, name: "SEARCH_FLAGS_EXACT_MATCH" },
]);

/**
 * @generated from enum avn.connect.v1.SortOrder
 */
export enum SortOrder {
  /**
   * @generated from enum value: SORT_ORDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SORT_ORDER_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: SORT_ORDER_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortOrder)
proto3.util.setEnumType(SortOrder, "avn.connect.v1.SortOrder", [
  { no: 0, name: "SORT_ORDER_UNSPECIFIED" },
  { no: 1, name: "SORT_ORDER_ASC" },
  { no: 2, name: "SORT_ORDER_DESC" },
]);

/**
 * @generated from enum avn.connect.v1.EntityProperty
 */
export enum EntityProperty {
  /**
   * @generated from enum value: ENTITY_PROPERTY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ENTITY_PROPERTY_ID = 1;
   */
  ID = 1,

  /**
   * @generated from enum value: ENTITY_PROPERTY_NAME = 2;
   */
  NAME = 2,

  /**
   * @generated from enum value: ENTITY_PROPERTY_DESCRIPTION = 3;
   */
  DESCRIPTION = 3,

  /**
   * @generated from enum value: ENTITY_PROPERTY_CREATED = 4;
   */
  CREATED = 4,

  /**
   * @generated from enum value: ENTITY_PROPERTY_UPDATED = 5;
   */
  UPDATED = 5,

  /**
   * @generated from enum value: ENTITY_PROPERTY_SIZE = 6;
   */
  SIZE = 6,

  /**
   * @generated from enum value: ENTITY_PROPERTY_SORT_ORDER = 7;
   */
  SORT_ORDER = 7,

  /**
   * @generated from enum value: ENTITY_PROPERTY_ICON_URL = 8;
   */
  ICON_URL = 8,

  /**
   * @generated from enum value: ENTITY_PROPERTY_INSTRUCTIONS = 9;
   */
  INSTRUCTIONS = 9,

  /**
   * @generated from enum value: ENTITY_PROPERTY_CONTEXT = 10;
   */
  CONTEXT = 10,

  /**
   * @generated from enum value: ENTITY_PROPERTY_ASSET_ID = 11;
   */
  ASSET_ID = 11,

  /**
   * @generated from enum value: ENTITY_PROPERTY_LANGUAGE_ID = 12;
   */
  LANGUAGE_ID = 12,

  /**
   * @generated from enum value: ENTITY_PROPERTY_PUBLISHED = 13;
   */
  PUBLISHED = 13,

  /**
   * @generated from enum value: ENTITY_PROPERTY_AVAILABLE = 14;
   */
  AVAILABLE = 14,

  /**
   * @generated from enum value: ENTITY_PROPERTY_FEATURED = 15;
   */
  FEATURED = 15,

  /**
   * @generated from enum value: ENTITY_PROPERTY_MAC_ADDRESS = 16;
   */
  MAC_ADDRESS = 16,

  /**
   * @generated from enum value: ENTITY_PROPERTY_SW_VERSION = 17;
   */
  SW_VERSION = 17,

  /**
   * @generated from enum value: ENTITY_PROPERTY_HOSTNAME = 18;
   */
  HOSTNAME = 18,

  /**
   * @generated from enum value: ENTITY_PROPERTY_PLATFORM = 19;
   */
  PLATFORM = 19,

  /**
   * @generated from enum value: ENTITY_PROPERTY_APPROVED = 20;
   */
  APPROVED = 20,

  /**
   * @generated from enum value: ENTITY_PROPERTY_FILENAME = 21;
   */
  FILENAME = 21,

  /**
   * @generated from enum value: ENTITY_PROPERTY_ITEM_COUNT = 22;
   */
  ITEM_COUNT = 22,

  /**
   * @generated from enum value: ENTITY_PROPERTY_TRACK_COUNT = 23;
   */
  TRACK_COUNT = 23,

  /**
   * @generated from enum value: ENTITY_PROPERTY_TAGS = 24;
   */
  TAGS = 24,

  /**
   * @generated from enum value: ENTITY_PROPERTY_ADDRESS = 25;
   */
  ADDRESS = 25,

  /**
   * @generated from enum value: ENTITY_PROPERTY_CITY = 26;
   */
  CITY = 26,

  /**
   * @generated from enum value: ENTITY_PROPERTY_STATE = 27;
   */
  STATE = 27,

  /**
   * @generated from enum value: ENTITY_PROPERTY_POSTCODE = 28;
   */
  POSTCODE = 28,

  /**
   * @generated from enum value: ENTITY_PROPERTY_PHONE = 29;
   */
  PHONE = 29,

  /**
   * @generated from enum value: ENTITY_PROPERTY_EMAIL = 30;
   */
  EMAIL = 30,

  /**
   * @generated from enum value: ENTITY_PROPERTY_KEYWORDS = 31;
   */
  KEYWORDS = 31,
}
// Retrieve enum metadata with: proto3.getEnumType(EntityProperty)
proto3.util.setEnumType(EntityProperty, "avn.connect.v1.EntityProperty", [
  { no: 0, name: "ENTITY_PROPERTY_UNSPECIFIED" },
  { no: 1, name: "ENTITY_PROPERTY_ID" },
  { no: 2, name: "ENTITY_PROPERTY_NAME" },
  { no: 3, name: "ENTITY_PROPERTY_DESCRIPTION" },
  { no: 4, name: "ENTITY_PROPERTY_CREATED" },
  { no: 5, name: "ENTITY_PROPERTY_UPDATED" },
  { no: 6, name: "ENTITY_PROPERTY_SIZE" },
  { no: 7, name: "ENTITY_PROPERTY_SORT_ORDER" },
  { no: 8, name: "ENTITY_PROPERTY_ICON_URL" },
  { no: 9, name: "ENTITY_PROPERTY_INSTRUCTIONS" },
  { no: 10, name: "ENTITY_PROPERTY_CONTEXT" },
  { no: 11, name: "ENTITY_PROPERTY_ASSET_ID" },
  { no: 12, name: "ENTITY_PROPERTY_LANGUAGE_ID" },
  { no: 13, name: "ENTITY_PROPERTY_PUBLISHED" },
  { no: 14, name: "ENTITY_PROPERTY_AVAILABLE" },
  { no: 15, name: "ENTITY_PROPERTY_FEATURED" },
  { no: 16, name: "ENTITY_PROPERTY_MAC_ADDRESS" },
  { no: 17, name: "ENTITY_PROPERTY_SW_VERSION" },
  { no: 18, name: "ENTITY_PROPERTY_HOSTNAME" },
  { no: 19, name: "ENTITY_PROPERTY_PLATFORM" },
  { no: 20, name: "ENTITY_PROPERTY_APPROVED" },
  { no: 21, name: "ENTITY_PROPERTY_FILENAME" },
  { no: 22, name: "ENTITY_PROPERTY_ITEM_COUNT" },
  { no: 23, name: "ENTITY_PROPERTY_TRACK_COUNT" },
  { no: 24, name: "ENTITY_PROPERTY_TAGS" },
  { no: 25, name: "ENTITY_PROPERTY_ADDRESS" },
  { no: 26, name: "ENTITY_PROPERTY_CITY" },
  { no: 27, name: "ENTITY_PROPERTY_STATE" },
  { no: 28, name: "ENTITY_PROPERTY_POSTCODE" },
  { no: 29, name: "ENTITY_PROPERTY_PHONE" },
  { no: 30, name: "ENTITY_PROPERTY_EMAIL" },
  { no: 31, name: "ENTITY_PROPERTY_KEYWORDS" },
]);

/**
 * Common info for channels, profiles, categories, and activities
 *
 * @generated from message avn.connect.v1.EntityInfo
 */
export class EntityInfo extends Message<EntityInfo> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 3;
   */
  updated?: Timestamp;

  /**
   * @generated from field: string icon_url = 4;
   */
  iconUrl = "";

  /**
   * @generated from field: string preview_url = 5;
   */
  previewUrl = "";

  /**
   * An indication of the origin of the entity
   *
   * @generated from field: optional string context = 6;
   */
  context?: string;

  /**
   * @generated from field: repeated int32 tags = 7;
   */
  tags: number[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp published = 8;
   */
  published?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp featured = 9;
   */
  featured?: Timestamp;

  /**
   * @generated from oneof avn.connect.v1.EntityInfo.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 10;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 11;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool available = 20;
   */
  available = false;

  /**
   * @generated from field: optional string asset_id = 21;
   */
  assetId?: string;

  /**
   * @generated from field: optional int64 size_bytes = 22;
   */
  sizeBytes?: bigint;

  /**
   * @generated from field: optional int32 item_count = 30;
   */
  itemCount?: number;

  /**
   * @generated from field: optional int32 track_count = 31;
   */
  trackCount?: number;

  constructor(data?: PartialMessage<EntityInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EntityInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "updated", kind: "message", T: Timestamp },
    { no: 4, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "published", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "featured", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 11, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 20, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 30, name: "item_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 31, name: "track_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityInfo {
    return new EntityInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityInfo {
    return new EntityInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityInfo {
    return new EntityInfo().fromJsonString(jsonString, options);
  }

  static equals(a: EntityInfo | PlainMessage<EntityInfo> | undefined, b: EntityInfo | PlainMessage<EntityInfo> | undefined): boolean {
    return proto3.util.equals(EntityInfo, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetEntityRequest
 */
export class GetEntityRequest extends Message<GetEntityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * Override to the user agent language (an empty string signifies no translation should be made)
   *
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec icon_spec = 4;
   */
  iconSpec?: TranscodeImageSpec;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec preview_spec = 5;
   */
  previewSpec?: TranscodeImageSpec;

  constructor(data?: PartialMessage<GetEntityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetEntityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "icon_spec", kind: "message", T: TranscodeImageSpec, opt: true },
    { no: 5, name: "preview_spec", kind: "message", T: TranscodeImageSpec, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEntityRequest {
    return new GetEntityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEntityRequest {
    return new GetEntityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEntityRequest {
    return new GetEntityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEntityRequest | PlainMessage<GetEntityRequest> | undefined, b: GetEntityRequest | PlainMessage<GetEntityRequest> | undefined): boolean {
    return proto3.util.equals(GetEntityRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateEntityRequest
 */
export class CreateEntityRequest extends Message<CreateEntityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Entities with no organization belong to the creating user by default
   *
   * @generated from field: optional int32 organization_id = 2;
   */
  organizationId?: number;

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  constructor(data?: PartialMessage<CreateEntityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateEntityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEntityRequest {
    return new CreateEntityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEntityRequest {
    return new CreateEntityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEntityRequest {
    return new CreateEntityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEntityRequest | PlainMessage<CreateEntityRequest> | undefined, b: CreateEntityRequest | PlainMessage<CreateEntityRequest> | undefined): boolean {
    return proto3.util.equals(CreateEntityRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateEntityResponse
 */
export class CreateEntityResponse extends Message<CreateEntityResponse> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  constructor(data?: PartialMessage<CreateEntityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateEntityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEntityResponse {
    return new CreateEntityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEntityResponse {
    return new CreateEntityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEntityResponse {
    return new CreateEntityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEntityResponse | PlainMessage<CreateEntityResponse> | undefined, b: CreateEntityResponse | PlainMessage<CreateEntityResponse> | undefined): boolean {
    return proto3.util.equals(CreateEntityResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DeleteEntityRequest
 */
export class DeleteEntityRequest extends Message<DeleteEntityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  constructor(data?: PartialMessage<DeleteEntityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DeleteEntityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEntityRequest {
    return new DeleteEntityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEntityRequest {
    return new DeleteEntityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEntityRequest {
    return new DeleteEntityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEntityRequest | PlainMessage<DeleteEntityRequest> | undefined, b: DeleteEntityRequest | PlainMessage<DeleteEntityRequest> | undefined): boolean {
    return proto3.util.equals(DeleteEntityRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CopyEntityRequest
 */
export class CopyEntityRequest extends Message<CopyEntityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Entities with no organization belong to the creating user by default
   *
   * @generated from field: optional int32 organization_id = 2;
   */
  organizationId?: number;

  /**
   * Target entity to copy
   *
   * @generated from field: optional int32 target_id = 3;
   */
  targetId?: number;

  constructor(data?: PartialMessage<CopyEntityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CopyEntityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "target_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyEntityRequest {
    return new CopyEntityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyEntityRequest {
    return new CopyEntityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyEntityRequest {
    return new CopyEntityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CopyEntityRequest | PlainMessage<CopyEntityRequest> | undefined, b: CopyEntityRequest | PlainMessage<CopyEntityRequest> | undefined): boolean {
    return proto3.util.equals(CopyEntityRequest, a, b);
  }
}

/**
 * Common request for entity lists
 *
 * @generated from message avn.connect.v1.EntityInfoListRequest
 */
export class EntityInfoListRequest extends Message<EntityInfoListRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * IDs of container elements (e.g. categories) or owner organizations (i.e. channels)
   *
   * @generated from field: repeated int32 entity_ids = 2;
   */
  entityIds: number[] = [];

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  /**
   * Search text fields
   *
   * @generated from field: optional avn.connect.v1.TextSearch text_search = 4;
   */
  textSearch?: TextSearch;

  /**
   * Return entities for which all the TagFilters are true
   *
   * @generated from field: repeated avn.connect.v1.TagFilter tag_filters = 5;
   */
  tagFilters: TagFilter[] = [];

  /**
   * Clauses to order the results by
   *
   * @generated from field: repeated avn.connect.v1.OrderClause order_by = 7;
   */
  orderBy: OrderClause[] = [];

  /**
   * @generated from field: optional int32 page_size = 8;
   */
  pageSize?: number;

  /**
   * @generated from field: optional string page_token = 9;
   */
  pageToken?: string;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec icon_spec = 10;
   */
  iconSpec?: TranscodeImageSpec;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec preview_spec = 11;
   */
  previewSpec?: TranscodeImageSpec;

  constructor(data?: PartialMessage<EntityInfoListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EntityInfoListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "text_search", kind: "message", T: TextSearch, opt: true },
    { no: 5, name: "tag_filters", kind: "message", T: TagFilter, repeated: true },
    { no: 7, name: "order_by", kind: "message", T: OrderClause, repeated: true },
    { no: 8, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "icon_spec", kind: "message", T: TranscodeImageSpec, opt: true },
    { no: 11, name: "preview_spec", kind: "message", T: TranscodeImageSpec, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityInfoListRequest {
    return new EntityInfoListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityInfoListRequest {
    return new EntityInfoListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityInfoListRequest {
    return new EntityInfoListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EntityInfoListRequest | PlainMessage<EntityInfoListRequest> | undefined, b: EntityInfoListRequest | PlainMessage<EntityInfoListRequest> | undefined): boolean {
    return proto3.util.equals(EntityInfoListRequest, a, b);
  }
}

/**
 * Common response for entity lists
 *
 * @generated from message avn.connect.v1.EntityInfoListResponse
 */
export class EntityInfoListResponse extends Message<EntityInfoListResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.EntityInfo results = 1;
   */
  results: EntityInfo[] = [];

  /**
   * @generated from field: optional string next_page_token = 2;
   */
  nextPageToken?: string;

  constructor(data?: PartialMessage<EntityInfoListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EntityInfoListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: EntityInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityInfoListResponse {
    return new EntityInfoListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityInfoListResponse {
    return new EntityInfoListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityInfoListResponse {
    return new EntityInfoListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EntityInfoListResponse | PlainMessage<EntityInfoListResponse> | undefined, b: EntityInfoListResponse | PlainMessage<EntityInfoListResponse> | undefined): boolean {
    return proto3.util.equals(EntityInfoListResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddChildrenRequest
 */
export class AddChildrenRequest extends Message<AddChildrenRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 parent_id = 2;
   */
  parentId = 0;

  /**
   * @generated from field: repeated int32 child_ids = 3;
   */
  childIds: number[] = [];

  /**
   * @generated from field: optional bool replace_existing = 4;
   */
  replaceExisting?: boolean;

  constructor(data?: PartialMessage<AddChildrenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddChildrenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "parent_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "child_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "replace_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddChildrenRequest {
    return new AddChildrenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddChildrenRequest {
    return new AddChildrenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddChildrenRequest {
    return new AddChildrenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddChildrenRequest | PlainMessage<AddChildrenRequest> | undefined, b: AddChildrenRequest | PlainMessage<AddChildrenRequest> | undefined): boolean {
    return proto3.util.equals(AddChildrenRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RemoveChildrenRequest
 */
export class RemoveChildrenRequest extends Message<RemoveChildrenRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 parent_id = 2;
   */
  parentId = 0;

  /**
   * @generated from field: repeated int32 child_ids = 3;
   */
  childIds: number[] = [];

  constructor(data?: PartialMessage<RemoveChildrenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RemoveChildrenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "parent_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "child_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveChildrenRequest {
    return new RemoveChildrenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveChildrenRequest {
    return new RemoveChildrenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveChildrenRequest {
    return new RemoveChildrenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveChildrenRequest | PlainMessage<RemoveChildrenRequest> | undefined, b: RemoveChildrenRequest | PlainMessage<RemoveChildrenRequest> | undefined): boolean {
    return proto3.util.equals(RemoveChildrenRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.EntityMetadata
 */
export class EntityMetadata extends Message<EntityMetadata> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: string key = 2;
   */
  key = "";

  /**
   * @generated from field: optional string value = 3;
   */
  value?: string;

  constructor(data?: PartialMessage<EntityMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EntityMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityMetadata {
    return new EntityMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityMetadata {
    return new EntityMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityMetadata {
    return new EntityMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: EntityMetadata | PlainMessage<EntityMetadata> | undefined, b: EntityMetadata | PlainMessage<EntityMetadata> | undefined): boolean {
    return proto3.util.equals(EntityMetadata, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MetadataRequest
 */
export class MetadataRequest extends Message<MetadataRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: avn.connect.v1.EntityMetadata metadata = 2;
   */
  metadata?: EntityMetadata;

  constructor(data?: PartialMessage<MetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "metadata", kind: "message", T: EntityMetadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetadataRequest {
    return new MetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetadataRequest {
    return new MetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetadataRequest {
    return new MetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MetadataRequest | PlainMessage<MetadataRequest> | undefined, b: MetadataRequest | PlainMessage<MetadataRequest> | undefined): boolean {
    return proto3.util.equals(MetadataRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMetadataResponse
 */
export class GetMetadataResponse extends Message<GetMetadataResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.EntityMetadata results = 1;
   */
  results: EntityMetadata[] = [];

  constructor(data?: PartialMessage<GetMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: EntityMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMetadataResponse {
    return new GetMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMetadataResponse {
    return new GetMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMetadataResponse {
    return new GetMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMetadataResponse | PlainMessage<GetMetadataResponse> | undefined, b: GetMetadataResponse | PlainMessage<GetMetadataResponse> | undefined): boolean {
    return proto3.util.equals(GetMetadataResponse, a, b);
  }
}

/**
 * Generic pattern for entity property setting
 *
 * @generated from message avn.connect.v1.SetEntityPropertiesRequest
 */
export class SetEntityPropertiesRequest extends Message<SetEntityPropertiesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated avn.connect.v1.EntityPropertyState property_states = 3;
   */
  propertyStates: EntityPropertyState[] = [];

  constructor(data?: PartialMessage<SetEntityPropertiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SetEntityPropertiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "property_states", kind: "message", T: EntityPropertyState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetEntityPropertiesRequest {
    return new SetEntityPropertiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetEntityPropertiesRequest {
    return new SetEntityPropertiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetEntityPropertiesRequest {
    return new SetEntityPropertiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetEntityPropertiesRequest | PlainMessage<SetEntityPropertiesRequest> | undefined, b: SetEntityPropertiesRequest | PlainMessage<SetEntityPropertiesRequest> | undefined): boolean {
    return proto3.util.equals(SetEntityPropertiesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.EntityPropertyState
 */
export class EntityPropertyState extends Message<EntityPropertyState> {
  /**
   * @generated from field: avn.connect.v1.EntityProperty property = 3;
   */
  property = EntityProperty.UNSPECIFIED;

  /**
   * @generated from oneof avn.connect.v1.EntityPropertyState.state
   */
  state: {
    /**
     * @generated from field: bool bool = 4;
     */
    value: boolean;
    case: "bool";
  } | {
    /**
     * @generated from field: int32 int32 = 5;
     */
    value: number;
    case: "int32";
  } | {
    /**
     * @generated from field: int64 int64 = 6;
     */
    value: bigint;
    case: "int64";
  } | {
    /**
     * @generated from field: float float = 7;
     */
    value: number;
    case: "float";
  } | {
    /**
     * @generated from field: double double = 8;
     */
    value: number;
    case: "double";
  } | {
    /**
     * @generated from field: string string = 9;
     */
    value: string;
    case: "string";
  } | {
    /**
     * @generated from field: bytes bytes = 10;
     */
    value: Uint8Array;
    case: "bytes";
  } | {
    /**
     * @generated from field: google.protobuf.Timestamp timestamp = 11;
     */
    value: Timestamp;
    case: "timestamp";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EntityPropertyState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.EntityPropertyState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "property", kind: "enum", T: proto3.getEnumType(EntityProperty) },
    { no: 4, name: "bool", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "state" },
    { no: 5, name: "int32", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "state" },
    { no: 6, name: "int64", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "state" },
    { no: 7, name: "float", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "state" },
    { no: 8, name: "double", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "state" },
    { no: 9, name: "string", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "state" },
    { no: 10, name: "bytes", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "state" },
    { no: 11, name: "timestamp", kind: "message", T: Timestamp, oneof: "state" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityPropertyState {
    return new EntityPropertyState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityPropertyState {
    return new EntityPropertyState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityPropertyState {
    return new EntityPropertyState().fromJsonString(jsonString, options);
  }

  static equals(a: EntityPropertyState | PlainMessage<EntityPropertyState> | undefined, b: EntityPropertyState | PlainMessage<EntityPropertyState> | undefined): boolean {
    return proto3.util.equals(EntityPropertyState, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TextSearch
 */
export class TextSearch extends Message<TextSearch> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  /**
   * @generated from field: repeated avn.connect.v1.EntityProperty properties = 2;
   */
  properties: EntityProperty[] = [];

  /**
   * @generated from field: repeated avn.connect.v1.SearchFlags conditions = 3;
   */
  conditions: SearchFlags[] = [];

  constructor(data?: PartialMessage<TextSearch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TextSearch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "properties", kind: "enum", T: proto3.getEnumType(EntityProperty), repeated: true },
    { no: 3, name: "conditions", kind: "enum", T: proto3.getEnumType(SearchFlags), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextSearch {
    return new TextSearch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextSearch {
    return new TextSearch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextSearch {
    return new TextSearch().fromJsonString(jsonString, options);
  }

  static equals(a: TextSearch | PlainMessage<TextSearch> | undefined, b: TextSearch | PlainMessage<TextSearch> | undefined): boolean {
    return proto3.util.equals(TextSearch, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.OrderClause
 */
export class OrderClause extends Message<OrderClause> {
  /**
   * @generated from field: avn.connect.v1.EntityProperty property = 7;
   */
  property = EntityProperty.UNSPECIFIED;

  /**
   * @generated from field: optional avn.connect.v1.SortOrder sort_order = 8;
   */
  sortOrder?: SortOrder;

  constructor(data?: PartialMessage<OrderClause>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.OrderClause";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "property", kind: "enum", T: proto3.getEnumType(EntityProperty) },
    { no: 8, name: "sort_order", kind: "enum", T: proto3.getEnumType(SortOrder), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderClause {
    return new OrderClause().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderClause {
    return new OrderClause().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderClause {
    return new OrderClause().fromJsonString(jsonString, options);
  }

  static equals(a: OrderClause | PlainMessage<OrderClause> | undefined, b: OrderClause | PlainMessage<OrderClause> | undefined): boolean {
    return proto3.util.equals(OrderClause, a, b);
  }
}

