// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/geo.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from enum avn.connect.v1.GeoPlaceType
 */
export enum GeoPlaceType {
  /**
   * @generated from enum value: GEO_PLACE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_BLOCK = 1;
   */
  BLOCK = 1,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_COUNTRY = 2;
   */
  COUNTRY = 2,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_DISTRICT = 3;
   */
  DISTRICT = 3,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_INTERPOLATED_ADDRESS = 4;
   */
  INTERPOLATED_ADDRESS = 4,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_INTERSECTION = 5;
   */
  INTERSECTION = 5,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_LOCALITY = 6;
   */
  LOCALITY = 6,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_POINT_ADDRESS = 7;
   */
  POINT_ADDRESS = 7,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_POINT_OF_INTEREST = 8;
   */
  POINT_OF_INTEREST = 8,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_POSTAL_CODE = 9;
   */
  POSTAL_CODE = 9,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_REGION = 10;
   */
  REGION = 10,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_STREET = 11;
   */
  STREET = 11,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_SUB_BLOCK = 12;
   */
  SUB_BLOCK = 12,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_SUB_DISTRICT = 13;
   */
  SUB_DISTRICT = 13,

  /**
   * @generated from enum value: GEO_PLACE_TYPE_SUB_REGION = 14;
   */
  SUB_REGION = 14,
}
// Retrieve enum metadata with: proto3.getEnumType(GeoPlaceType)
proto3.util.setEnumType(GeoPlaceType, "avn.connect.v1.GeoPlaceType", [
  { no: 0, name: "GEO_PLACE_TYPE_UNSPECIFIED" },
  { no: 1, name: "GEO_PLACE_TYPE_BLOCK" },
  { no: 2, name: "GEO_PLACE_TYPE_COUNTRY" },
  { no: 3, name: "GEO_PLACE_TYPE_DISTRICT" },
  { no: 4, name: "GEO_PLACE_TYPE_INTERPOLATED_ADDRESS" },
  { no: 5, name: "GEO_PLACE_TYPE_INTERSECTION" },
  { no: 6, name: "GEO_PLACE_TYPE_LOCALITY" },
  { no: 7, name: "GEO_PLACE_TYPE_POINT_ADDRESS" },
  { no: 8, name: "GEO_PLACE_TYPE_POINT_OF_INTEREST" },
  { no: 9, name: "GEO_PLACE_TYPE_POSTAL_CODE" },
  { no: 10, name: "GEO_PLACE_TYPE_REGION" },
  { no: 11, name: "GEO_PLACE_TYPE_STREET" },
  { no: 12, name: "GEO_PLACE_TYPE_SUB_BLOCK" },
  { no: 13, name: "GEO_PLACE_TYPE_SUB_DISTRICT" },
  { no: 14, name: "GEO_PLACE_TYPE_SUB_REGION" },
]);

/**
 * @generated from message avn.connect.v1.GetGeoPlaceRequest
 */
export class GetGeoPlaceRequest extends Message<GetGeoPlaceRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string place_id = 2;
   */
  placeId = "";

  /**
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetGeoPlaceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetGeoPlaceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "place_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGeoPlaceRequest {
    return new GetGeoPlaceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGeoPlaceRequest {
    return new GetGeoPlaceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGeoPlaceRequest {
    return new GetGeoPlaceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGeoPlaceRequest | PlainMessage<GetGeoPlaceRequest> | undefined, b: GetGeoPlaceRequest | PlainMessage<GetGeoPlaceRequest> | undefined): boolean {
    return proto3.util.equals(GetGeoPlaceRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetGeoPlaceResponse
 */
export class GetGeoPlaceResponse extends Message<GetGeoPlaceResponse> {
  /**
   * @generated from field: avn.connect.v1.GeoPlace place = 1;
   */
  place?: GeoPlace;

  /**
   * Heuristically composed ClassConnect fields
   *
   * @generated from field: avn.connect.v1.GeoAddress address = 2;
   */
  address?: GeoAddress;

  /**
   * Full result from geolocation service
   *
   * @generated from field: optional avn.connect.v1.AwsPlacesAddress aws_places_address = 3;
   */
  awsPlacesAddress?: AwsPlacesAddress;

  /**
   * @generated from field: repeated avn.connect.v1.GeoContact emails = 4;
   */
  emails: GeoContact[] = [];

  /**
   * @generated from field: repeated avn.connect.v1.GeoContact faxes = 5;
   */
  faxes: GeoContact[] = [];

  /**
   * @generated from field: repeated avn.connect.v1.GeoContact phones = 6;
   */
  phones: GeoContact[] = [];

  /**
   * @generated from field: repeated avn.connect.v1.GeoContact websites = 7;
   */
  websites: GeoContact[] = [];

  constructor(data?: PartialMessage<GetGeoPlaceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetGeoPlaceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "place", kind: "message", T: GeoPlace },
    { no: 2, name: "address", kind: "message", T: GeoAddress },
    { no: 3, name: "aws_places_address", kind: "message", T: AwsPlacesAddress, opt: true },
    { no: 4, name: "emails", kind: "message", T: GeoContact, repeated: true },
    { no: 5, name: "faxes", kind: "message", T: GeoContact, repeated: true },
    { no: 6, name: "phones", kind: "message", T: GeoContact, repeated: true },
    { no: 7, name: "websites", kind: "message", T: GeoContact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGeoPlaceResponse {
    return new GetGeoPlaceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGeoPlaceResponse {
    return new GetGeoPlaceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGeoPlaceResponse {
    return new GetGeoPlaceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGeoPlaceResponse | PlainMessage<GetGeoPlaceResponse> | undefined, b: GetGeoPlaceResponse | PlainMessage<GetGeoPlaceResponse> | undefined): boolean {
    return proto3.util.equals(GetGeoPlaceResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoSuggestRequest
 */
export class GeoSuggestRequest extends Message<GeoSuggestRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Text to search for
   *
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * Optional list of countries to include in the search
   *
   * @generated from field: repeated string country_ids = 3;
   */
  countryIds: string[] = [];

  /**
   * Optional position to use for ordering results
   *
   * @generated from field: optional avn.connect.v1.GeoLocation bias_position = 4;
   */
  biasPosition?: GeoLocation;

  /**
   * Max number of results
   *
   * @generated from field: optional int32 page_size = 5;
   */
  pageSize?: number;

  /**
   * Language override
   *
   * @generated from field: optional string language_id = 6;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GeoSuggestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoSuggestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "bias_position", kind: "message", T: GeoLocation, opt: true },
    { no: 5, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoSuggestRequest {
    return new GeoSuggestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoSuggestRequest {
    return new GeoSuggestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoSuggestRequest {
    return new GeoSuggestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GeoSuggestRequest | PlainMessage<GeoSuggestRequest> | undefined, b: GeoSuggestRequest | PlainMessage<GeoSuggestRequest> | undefined): boolean {
    return proto3.util.equals(GeoSuggestRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoSuggestResponse
 */
export class GeoSuggestResponse extends Message<GeoSuggestResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.GeoPlace results = 1;
   */
  results: GeoPlace[] = [];

  constructor(data?: PartialMessage<GeoSuggestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoSuggestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: GeoPlace, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoSuggestResponse {
    return new GeoSuggestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoSuggestResponse {
    return new GeoSuggestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoSuggestResponse {
    return new GeoSuggestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GeoSuggestResponse | PlainMessage<GeoSuggestResponse> | undefined, b: GeoSuggestResponse | PlainMessage<GeoSuggestResponse> | undefined): boolean {
    return proto3.util.equals(GeoSuggestResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoPlace
 */
export class GeoPlace extends Message<GeoPlace> {
  /**
   * @generated from field: string place_id = 1;
   */
  placeId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: avn.connect.v1.GeoPlaceType place_type = 3;
   */
  placeType = GeoPlaceType.UNSPECIFIED;

  /**
   * @generated from field: optional avn.connect.v1.GeoLocation position = 7;
   */
  position?: GeoLocation;

  constructor(data?: PartialMessage<GeoPlace>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoPlace";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "place_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "place_type", kind: "enum", T: proto3.getEnumType(GeoPlaceType) },
    { no: 7, name: "position", kind: "message", T: GeoLocation, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoPlace {
    return new GeoPlace().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoPlace {
    return new GeoPlace().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoPlace {
    return new GeoPlace().fromJsonString(jsonString, options);
  }

  static equals(a: GeoPlace | PlainMessage<GeoPlace> | undefined, b: GeoPlace | PlainMessage<GeoPlace> | undefined): boolean {
    return proto3.util.equals(GeoPlace, a, b);
  }
}

/**
 * Full results from AWS Places service
 *
 * @generated from message avn.connect.v1.AwsPlacesAddress
 */
export class AwsPlacesAddress extends Message<AwsPlacesAddress> {
  /**
   * @generated from field: string address_number = 1;
   */
  addressNumber = "";

  /**
   * @generated from field: string building = 2;
   */
  building = "";

  /**
   * @generated from field: string street = 3;
   */
  street = "";

  /**
   * @generated from field: string block = 4;
   */
  block = "";

  /**
   * @generated from field: string sub_block = 5;
   */
  subBlock = "";

  /**
   * @generated from field: repeated string intersection = 6;
   */
  intersection: string[] = [];

  /**
   * @generated from field: string locality = 7;
   */
  locality = "";

  /**
   * @generated from field: string region = 8;
   */
  region = "";

  /**
   * @generated from field: string sub_region = 9;
   */
  subRegion = "";

  /**
   * @generated from field: string district = 10;
   */
  district = "";

  /**
   * @generated from field: string sub_district = 11;
   */
  subDistrict = "";

  /**
   * @generated from field: string post_code = 12;
   */
  postCode = "";

  /**
   * @generated from field: string country_id = 13;
   */
  countryId = "";

  constructor(data?: PartialMessage<AwsPlacesAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AwsPlacesAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "building", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "block", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sub_block", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "intersection", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "locality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "sub_region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "district", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "sub_district", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "post_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AwsPlacesAddress {
    return new AwsPlacesAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AwsPlacesAddress {
    return new AwsPlacesAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AwsPlacesAddress {
    return new AwsPlacesAddress().fromJsonString(jsonString, options);
  }

  static equals(a: AwsPlacesAddress | PlainMessage<AwsPlacesAddress> | undefined, b: AwsPlacesAddress | PlainMessage<AwsPlacesAddress> | undefined): boolean {
    return proto3.util.equals(AwsPlacesAddress, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoAddress
 */
export class GeoAddress extends Message<GeoAddress> {
  /**
   * Street name and building name or number
   *
   * @generated from field: string street_address = 1;
   */
  streetAddress = "";

  /**
   * City, town, or village
   *
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * State or municipality
   *
   * @generated from field: string state = 3;
   */
  state = "";

  /**
   * Post or zip code
   *
   * @generated from field: string post_code = 4;
   */
  postCode = "";

  /**
   * @generated from field: string country_id = 5;
   */
  countryId = "";

  constructor(data?: PartialMessage<GeoAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "post_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoAddress {
    return new GeoAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoAddress {
    return new GeoAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoAddress {
    return new GeoAddress().fromJsonString(jsonString, options);
  }

  static equals(a: GeoAddress | PlainMessage<GeoAddress> | undefined, b: GeoAddress | PlainMessage<GeoAddress> | undefined): boolean {
    return proto3.util.equals(GeoAddress, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoContact
 */
export class GeoContact extends Message<GeoContact> {
  /**
   * @generated from field: string label = 1;
   */
  label = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<GeoContact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoContact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoContact {
    return new GeoContact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoContact {
    return new GeoContact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoContact {
    return new GeoContact().fromJsonString(jsonString, options);
  }

  static equals(a: GeoContact | PlainMessage<GeoContact> | undefined, b: GeoContact | PlainMessage<GeoContact> | undefined): boolean {
    return proto3.util.equals(GeoContact, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoLocation
 */
export class GeoLocation extends Message<GeoLocation> {
  /**
   * @generated from field: float lon = 1;
   */
  lon = 0;

  /**
   * @generated from field: float lat = 2;
   */
  lat = 0;

  constructor(data?: PartialMessage<GeoLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lon", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "lat", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoLocation {
    return new GeoLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoLocation {
    return new GeoLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoLocation {
    return new GeoLocation().fromJsonString(jsonString, options);
  }

  static equals(a: GeoLocation | PlainMessage<GeoLocation> | undefined, b: GeoLocation | PlainMessage<GeoLocation> | undefined): boolean {
    return proto3.util.equals(GeoLocation, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GeoBounds
 */
export class GeoBounds extends Message<GeoBounds> {
  /**
   * @generated from field: avn.connect.v1.GeoLocation sw = 1;
   */
  sw?: GeoLocation;

  /**
   * @generated from field: avn.connect.v1.GeoLocation ne = 2;
   */
  ne?: GeoLocation;

  constructor(data?: PartialMessage<GeoBounds>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GeoBounds";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sw", kind: "message", T: GeoLocation },
    { no: 2, name: "ne", kind: "message", T: GeoLocation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoBounds {
    return new GeoBounds().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoBounds {
    return new GeoBounds().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoBounds {
    return new GeoBounds().fromJsonString(jsonString, options);
  }

  static equals(a: GeoBounds | PlainMessage<GeoBounds> | undefined, b: GeoBounds | PlainMessage<GeoBounds> | undefined): boolean {
    return proto3.util.equals(GeoBounds, a, b);
  }
}

