import {
    createPromiseClient,
    PromiseClient,
    Transport,
} from "@connectrpc/connect"

import {
    createGrpcWebTransport,
} from "@connectrpc/connect-web"

import { ActivityService } from "../../gen/avn/connect/v1/activities_connect.js"
import { AvnfsService } from "../../gen/avn/connect/v1/avnfs_connect.js"
import { BlockadeService } from "../../gen/avn/connect/v1/blockade_connect.js"
import { CategoryService } from "../../gen/avn/connect/v1/categories_connect.js"
import { CertificateService } from "../../gen/avn/connect/v1/certificates_connect.js"
import { ChannelService } from "../../gen/avn/connect/v1/channels_connect.js"
import { ClientService } from "../../gen/avn/connect/v1/clients_connect.js"
import { CloudService } from "../../gen/avn/connect/v1/cloud_connect.js"
import { ContentService } from "../../gen/avn/connect/v1/content_connect.js"
import { CountryService } from "../../gen/avn/connect/v1/countries_connect.js"
import { DimensionService } from "../../gen/avn/connect/v1/dimensions_connect.js"
import { GeoService } from "../../gen/avn/connect/v1/geo_connect.js"
import { Health } from "../../gen/grpc/health/v1/healthcheck_connect.js"
import { KtxService } from "../../gen/avn/connect/v1/ktx_connect.js"
import { LanguageService } from "../../gen/avn/connect/v1/languages_connect.js"
import { LicenseService } from "../../gen/avn/connect/v1/licenses_connect.js"
import { MediaService } from "../../gen/avn/connect/v1/media_connect.js"
import { NeighborService } from "../../gen/avn/connect/v1/neighbors_connect.js"
import { OrganizationService } from "../../gen/avn/connect/v1/organizations_connect.js"
import { PassService } from "../../gen/avn/connect/v1/passes_connect.js"
import { PermissionService } from "../../gen/avn/connect/v1/permissions_connect.js"
import { ProfileService } from "../../gen/avn/connect/v1/profiles_connect.js"
import { RoleService } from "../../gen/avn/connect/v1/roles_connect.js"
import { RoomService } from "../../gen/avn/connect/v1/rooms_connect.js"
import { TagService } from "../../gen/avn/connect/v1/tags_connect.js"
import { ThreeSixtyCitiesService } from "../../gen/avn/connect/v1/three_sixty_cities_connect.js"
import { TranslationService } from "../../gen/avn/connect/v1/translations_connect.js"
import { UserService } from "../../gen/avn/connect/v1/users_connect.js"

export const GwebUrl = "https://gweb.avncloud.com"
export const GwebAlphaUrl = "https://gweb-alpha.avncloud.com"

export class ConnectServices {

    // Expects a grpc-web endpoint for the AVN Connect services
    constructor(public readonly gwebUrl: string = GwebUrl) {
        console.info(`AVNConnect endpoint '${gwebUrl}'`)

        this.transport = createGrpcWebTransport({ baseUrl: this.gwebUrl })

        this.Activities = createPromiseClient(ActivityService, this.transport)
        this.Avnfs = createPromiseClient(AvnfsService, this.transport)
        this.Blockade = createPromiseClient(BlockadeService, this.transport)
        this.Categories = createPromiseClient(CategoryService, this.transport)
        this.Certificates = createPromiseClient(CertificateService, this.transport)
        this.Channels = createPromiseClient(ChannelService, this.transport)
        this.Clients = createPromiseClient(ClientService, this.transport)
        this.Cloud = createPromiseClient(CloudService, this.transport)
        this.Content = createPromiseClient(ContentService, this.transport)
        this.Countries = createPromiseClient(CountryService, this.transport)
        this.Dimensions = createPromiseClient(DimensionService, this.transport)
        this.Geo = createPromiseClient(GeoService, this.transport)
        this.Health = createPromiseClient(Health, this.transport)
        this.Ktx = createPromiseClient(KtxService, this.transport)
        this.Languages = createPromiseClient(LanguageService, this.transport)
        this.Licenses = createPromiseClient(LicenseService, this.transport)
        this.Media = createPromiseClient(MediaService, this.transport)
        this.Neighbors = createPromiseClient(NeighborService, this.transport)
        this.Organizations = createPromiseClient(OrganizationService, this.transport)
        this.Passes = createPromiseClient(PassService, this.transport)
        this.Permissions = createPromiseClient(PermissionService, this.transport)
        this.Profiles = createPromiseClient(ProfileService, this.transport)
        this.Roles = createPromiseClient(RoleService, this.transport)
        this.Rooms = createPromiseClient(RoomService, this.transport)
        this.Tags = createPromiseClient(TagService, this.transport)
        this.ThreeSixtyCities = createPromiseClient(ThreeSixtyCitiesService, this.transport)
        this.Translations = createPromiseClient(TranslationService, this.transport)
        this.Users = createPromiseClient(UserService, this.transport)
    }

    private transport: Transport

    // Services

    public Activities : PromiseClient<typeof ActivityService>
    public Avnfs : PromiseClient<typeof AvnfsService>    
    public Blockade : PromiseClient<typeof BlockadeService>    
    public Categories : PromiseClient<typeof CategoryService>
    public Certificates : PromiseClient<typeof CertificateService>    
    public Channels : PromiseClient<typeof ChannelService>
    public Clients : PromiseClient<typeof ClientService>    
    public Cloud : PromiseClient<typeof CloudService>    
    public Content : PromiseClient<typeof ContentService>    
    public Countries : PromiseClient<typeof CountryService>    
    public Dimensions : PromiseClient<typeof DimensionService>
    public Geo : PromiseClient<typeof GeoService>    
    public Health : PromiseClient<typeof Health>
    public Ktx : PromiseClient<typeof KtxService>
    public Languages : PromiseClient<typeof LanguageService>    
    public Licenses : PromiseClient<typeof LicenseService>    
    public Media : PromiseClient<typeof MediaService>    
    public Neighbors : PromiseClient<typeof NeighborService>    
    public Organizations : PromiseClient<typeof OrganizationService>    
    public Passes : PromiseClient<typeof PassService>    
    public Permissions : PromiseClient<typeof PermissionService>    
    public Profiles : PromiseClient<typeof ProfileService>
    public Roles : PromiseClient<typeof RoleService>    
    public Rooms : PromiseClient<typeof RoomService>    
    public Tags : PromiseClient<typeof TagService>    
    public ThreeSixtyCities : PromiseClient<typeof ThreeSixtyCitiesService>    
    public Translations : PromiseClient<typeof TranslationService>    
    public Users : PromiseClient<typeof UserService>    

}


