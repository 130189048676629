// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/countries.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { Translation } from "./translations_pb.js";

/**
 * @generated from message avn.connect.v1.GetCountryRequest
 */
export class GetCountryRequest extends Message<GetCountryRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string country_id = 2;
   */
  countryId = "";

  /**
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetCountryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCountryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCountryRequest {
    return new GetCountryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCountryRequest {
    return new GetCountryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCountryRequest {
    return new GetCountryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCountryRequest | PlainMessage<GetCountryRequest> | undefined, b: GetCountryRequest | PlainMessage<GetCountryRequest> | undefined): boolean {
    return proto3.util.equals(GetCountryRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCountriesRequest
 */
export class GetCountriesRequest extends Message<GetCountriesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetCountriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCountriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCountriesRequest {
    return new GetCountriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCountriesRequest {
    return new GetCountriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCountriesRequest {
    return new GetCountriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCountriesRequest | PlainMessage<GetCountriesRequest> | undefined, b: GetCountriesRequest | PlainMessage<GetCountriesRequest> | undefined): boolean {
    return proto3.util.equals(GetCountriesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCountriesResponse
 */
export class GetCountriesResponse extends Message<GetCountriesResponse> {
  /**
   * @generated from field: map<string, avn.connect.v1.Country> results = 1;
   */
  results: { [key: string]: Country } = {};

  constructor(data?: PartialMessage<GetCountriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCountriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Country} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCountriesResponse {
    return new GetCountriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCountriesResponse {
    return new GetCountriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCountriesResponse {
    return new GetCountriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCountriesResponse | PlainMessage<GetCountriesResponse> | undefined, b: GetCountriesResponse | PlainMessage<GetCountriesResponse> | undefined): boolean {
    return proto3.util.equals(GetCountriesResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.Country
 */
export class Country extends Message<Country> {
  /**
   * ISO 3166-1 alpha-2 (default key)
   *
   * @generated from field: string country_id = 1;
   */
  countryId = "";

  /**
   * ISO 3166-1 alpha-3
   *
   * @generated from field: string alpha3 = 2;
   */
  alpha3 = "";

  /**
   * Name translated into request language
   *
   * @generated from field: avn.connect.v1.Translation name = 3;
   */
  name?: Translation;

  /**
   * Name in the default language of the country
   *
   * @generated from field: string native_name = 4;
   */
  nativeName = "";

  /**
   * @generated from field: string default_language_id = 5;
   */
  defaultLanguageId = "";

  /**
   * @generated from field: string default_locale_id = 6;
   */
  defaultLocaleId = "";

  /**
   * @generated from field: int32 default_organization_id = 7;
   */
  defaultOrganizationId = 0;

  /**
   * @generated from field: optional int32 tag_id = 8;
   */
  tagId?: number;

  constructor(data?: PartialMessage<Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alpha3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "message", T: Translation },
    { no: 4, name: "native_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "default_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "default_locale_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "default_organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Country {
    return new Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJsonString(jsonString, options);
  }

  static equals(a: Country | PlainMessage<Country> | undefined, b: Country | PlainMessage<Country> | undefined): boolean {
    return proto3.util.equals(Country, a, b);
  }
}

