// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/activities.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";
import { AndroidPackage } from "./packages_pb.js";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from enum avn.connect.v1.ActivityType
 */
export enum ActivityType {
  /**
   * @generated from enum value: ACTIVITY_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTIVITY_TYPE_FILE = 1;
   */
  FILE = 1,

  /**
   * @generated from enum value: ACTIVITY_TYPE_URL = 2;
   */
  URL = 2,

  /**
   * @generated from enum value: ACTIVITY_TYPE_APP = 3;
   */
  APP = 3,

  /**
   * @generated from enum value: ACTIVITY_TYPE_FOLDER = 4;
   */
  FOLDER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ActivityType)
proto3.util.setEnumType(ActivityType, "avn.connect.v1.ActivityType", [
  { no: 0, name: "ACTIVITY_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACTIVITY_TYPE_FILE" },
  { no: 2, name: "ACTIVITY_TYPE_URL" },
  { no: 3, name: "ACTIVITY_TYPE_APP" },
  { no: 4, name: "ACTIVITY_TYPE_FOLDER" },
]);

/**
 * @generated from enum avn.connect.v1.ActivityFileType
 */
export enum ActivityFileType {
  /**
   * @generated from enum value: ACTIVITY_FILE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTIVITY_FILE_TYPE_CONTENT = 1;
   */
  CONTENT = 1,

  /**
   * @generated from enum value: ACTIVITY_FILE_TYPE_SCREENSHOT = 2;
   */
  SCREENSHOT = 2,

  /**
   * @generated from enum value: ACTIVITY_FILE_TYPE_PACKAGE = 3;
   */
  PACKAGE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ActivityFileType)
proto3.util.setEnumType(ActivityFileType, "avn.connect.v1.ActivityFileType", [
  { no: 0, name: "ACTIVITY_FILE_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACTIVITY_FILE_TYPE_CONTENT" },
  { no: 2, name: "ACTIVITY_FILE_TYPE_SCREENSHOT" },
  { no: 3, name: "ACTIVITY_FILE_TYPE_PACKAGE" },
]);

/**
 * @generated from message avn.connect.v1.Activity
 */
export class Activity extends Message<Activity> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: string preview_url = 4;
   */
  previewUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp updated = 5;
   */
  updated?: Timestamp;

  /**
   * @generated from field: optional avn.connect.v1.Translation description = 6;
   */
  description?: Translation;

  /**
   * @generated from field: optional avn.connect.v1.Translation instructions = 7;
   */
  instructions?: Translation;

  /**
   * @generated from field: optional avn.connect.v1.Translation keywords = 21;
   */
  keywords?: Translation;

  /**
   * @generated from field: string language_id = 8;
   */
  languageId = "";

  /**
   * @generated from field: repeated int32 tags = 9;
   */
  tags: number[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp published = 10;
   */
  published?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp featured = 11;
   */
  featured?: Timestamp;

  /**
   * @generated from oneof avn.connect.v1.Activity.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 12;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 13;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool deleted = 14;
   */
  deleted = false;

  /**
   * @generated from field: bool available = 15;
   */
  available = false;

  /**
   * @generated from field: string asset_id = 16;
   */
  assetId = "";

  /**
   * @generated from field: optional string context = 17;
   */
  context?: string;

  /**
   * @generated from field: repeated string screenshot_urls = 18;
   */
  screenshotUrls: string[] = [];

  /**
   * @generated from field: optional int64 size_bytes = 19;
   */
  sizeBytes?: bigint;

  /**
   * @generated from field: avn.connect.v1.ActivityType type = 20;
   */
  type = ActivityType.UNSPECIFIED;

  /**
   * ACTIVITY_TYPE_URL properties
   *
   * @generated from field: optional string url = 31;
   */
  url?: string;

  /**
   * ACTIVITY_TYPE_FILE properties
   *
   * @generated from field: repeated string file_urls = 32;
   */
  fileUrls: string[] = [];

  /**
   * ACTIVITY_TYPE_APP properties
   *
   * @generated from field: repeated avn.connect.v1.AndroidPackage android_packages = 33;
   */
  androidPackages: AndroidPackage[] = [];

  constructor(data?: PartialMessage<Activity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Activity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated", kind: "message", T: Timestamp },
    { no: 6, name: "description", kind: "message", T: Translation, opt: true },
    { no: 7, name: "instructions", kind: "message", T: Translation, opt: true },
    { no: 21, name: "keywords", kind: "message", T: Translation, opt: true },
    { no: 8, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "published", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "featured", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 13, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 14, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "screenshot_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 20, name: "type", kind: "enum", T: proto3.getEnumType(ActivityType) },
    { no: 31, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 33, name: "android_packages", kind: "message", T: AndroidPackage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Activity {
    return new Activity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Activity {
    return new Activity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Activity {
    return new Activity().fromJsonString(jsonString, options);
  }

  static equals(a: Activity | PlainMessage<Activity> | undefined, b: Activity | PlainMessage<Activity> | undefined): boolean {
    return proto3.util.equals(Activity, a, b);
  }
}

/**
 * Activities must have a type, so this interface is an extension of CreateEntityRequest
 *
 * @generated from message avn.connect.v1.CreateActivityRequest
 */
export class CreateActivityRequest extends Message<CreateActivityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Entities with no organization belong to the creating user by default
   *
   * @generated from field: optional int32 organization_id = 2;
   */
  organizationId?: number;

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 3;
   */
  languageId?: string;

  /**
   * Activity type
   *
   * @generated from field: avn.connect.v1.ActivityType type = 4;
   */
  type = ActivityType.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateActivityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateActivityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ActivityType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateActivityRequest {
    return new CreateActivityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateActivityRequest {
    return new CreateActivityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateActivityRequest {
    return new CreateActivityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateActivityRequest | PlainMessage<CreateActivityRequest> | undefined, b: CreateActivityRequest | PlainMessage<CreateActivityRequest> | undefined): boolean {
    return proto3.util.equals(CreateActivityRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddActivityFilesRequest
 */
export class AddActivityFilesRequest extends Message<AddActivityFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated string file_urls = 3;
   */
  fileUrls: string[] = [];

  /**
   * @generated from field: avn.connect.v1.ActivityFileType file_type = 4;
   */
  fileType = ActivityFileType.UNSPECIFIED;

  /**
   * Replace all existing files?
   *
   * @generated from field: optional bool replace_existing = 5;
   */
  replaceExisting?: boolean;

  constructor(data?: PartialMessage<AddActivityFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddActivityFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "file_type", kind: "enum", T: proto3.getEnumType(ActivityFileType) },
    { no: 5, name: "replace_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddActivityFilesRequest {
    return new AddActivityFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddActivityFilesRequest {
    return new AddActivityFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddActivityFilesRequest {
    return new AddActivityFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddActivityFilesRequest | PlainMessage<AddActivityFilesRequest> | undefined, b: AddActivityFilesRequest | PlainMessage<AddActivityFilesRequest> | undefined): boolean {
    return proto3.util.equals(AddActivityFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RemoveActivityFilesRequest
 */
export class RemoveActivityFilesRequest extends Message<RemoveActivityFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated string file_urls = 3;
   */
  fileUrls: string[] = [];

  /**
   * @generated from field: avn.connect.v1.ActivityFileType file_type = 4;
   */
  fileType = ActivityFileType.UNSPECIFIED;

  constructor(data?: PartialMessage<RemoveActivityFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RemoveActivityFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "file_type", kind: "enum", T: proto3.getEnumType(ActivityFileType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveActivityFilesRequest {
    return new RemoveActivityFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveActivityFilesRequest {
    return new RemoveActivityFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveActivityFilesRequest {
    return new RemoveActivityFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveActivityFilesRequest | PlainMessage<RemoveActivityFilesRequest> | undefined, b: RemoveActivityFilesRequest | PlainMessage<RemoveActivityFilesRequest> | undefined): boolean {
    return proto3.util.equals(RemoveActivityFilesRequest, a, b);
  }
}

